import React, { useState } from "react";
import './Home.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AnimationOnScroll } from "react-animation-on-scroll";
const Offer = ()=>{

  const [activeList, setActiveList] = useState({
    konsultacje:false,
    mezoterapia_iglowa:false,
    stymulatory_tkankowe:false,
    mezoterapia_mikroiglowa:false,
    mezoterapia_mikroiglowa_RF:false,
    falaRadiowa:false,
    oczyszczanieWodorowe:false,
    estGen:false,
    genFactor:false,
    dodatki:false,
    depilacjaLaserowa:false,
    peelingiKwasowe:false,
    kosmetyka_biala:false,
    trycho:false,
    stylizacjaRzes:false,
    stylizacjaBrwiIRzes:false,
    laminacjaBrwiIRzes:false,
    depilacjaWoskiemLycon:false,
    zabiegiNaCialo:false,
    zabiegiManualne:false,
    pielegnacjaDloniIStop:false
  })

  const checkAndSetActiveList =(clickedList)=> {
    setActiveList(prevState=> (
      {
        konsultacje:false,
        mezoterapia_iglowa:false,
        stymulatory_tkankowe:false,
        mezoterapia_mikroiglowa:false,
        mezoterapia_mikroiglowa_RF:false,
        falaRadiowa:false,
        oczyszczanieWodorowe:false,
        estGen:false,
        genFactor:false,
        dodatki:false,
        depilacjaLaserowa:false,
        peelingiKwasowe:false,
        kosmetyka_biala:false,
        trycho:false,
        stylizacjaRzes:false,
        stylizacjaBrwiIRzes:false,
        laminacjaBrwiIRzes:false,
        depilacjaWoskiemLycon:false,
        zabiegiNaCialo:false,
        zabiegiManualne:false,
        pielegnacjaDloniIStop:false,
      [clickedList] : true
    }))
  }

  console.log(activeList)

  const kosmetologia = {
    konsultacje: <ul>
    <li><div className="header">Konsultacja kosmetologiczna</div><div className="price">GRATIS</div></li>
    <li><div className="header">Kontrola po zabiegu</div><div className="price">GRATIS</div></li>
    
  </ul>,
  mezoterapia_iglowa: <ul>
  <li><div className="header">Okolica Oka</div><div className="price">290 zł</div></li>
  <li><div className="header">Twarz</div><div className="price">350 zł</div></li>
  <li><div className="header">Twarz, szyja</div><div className="price">390 zł</div></li>
  <li><div className="header">Twarz, szyja, dekolt</div><div className="price">450 zł</div></li>
  <li><div className="header">Na skóry głowy</div><div className="price">350 zł</div></li>
  <li><div className="header">Dłonie</div><div className="price">290 zł</div></li>
  <li><div className="header">Osocze Bogatopłytkowe</div><div className="price">500 zł</div></li>
  <li><div className="otherInfo">Cena za inne części ciała jest ustalana po indywidualnej konsultacji</div></li>
  
  
</ul>,
stymulatory_tkankowe: <ul>

{/*<li><div className="header">OKOLICE OKA</div></li>*/}
<li><div className="header">Profilo</div><div className="price">490 zł</div></li>
<li><div className="header">Elaj 40</div><div className="price">600 zł</div></li>
<li><div className="header">Karisma</div><div className="price">900 zł</div></li>
{/*<li><div className="header">TWARZ</div></li>*/}
<li><div className="header">Lumi Eyes</div><div className="price">490 zł</div></li>
<li><div className="header">Sunekos 200</div><div className="price">490 zł</div></li>
<li><div className="header">Tropokolagen twarz</div><div className="price">450 zł</div></li>
<li><div className="header">Tropokolagen twarz, szyja, dekolt</div><div className="price">600 zł</div></li>

</ul>,
  mezoterapia_mikroiglowa: <ul>
  <li><div className="header">Twarz </div><div className="price">350 zł</div></li>
  <li><div className="header">Twarz, szyja</div><div className="price">400 zł</div></li>
  <li><div className="header">Twarz, szyja, dekolt </div><div className="price">450 zł</div></li>
  <li><div className="header">Dłonie</div><div className="price">300 zł</div></li>
  <li><div className="otherInfo">Cena za inne części ciała jest ustalana po indywidualnej konsultacji</div></li>
</ul>,
  mezoterapia_mikroiglowa_RF: <ul>{/*Skin Lift 448kHz biostymulacja skóry*/}
  <li><div className="header">Twarz </div><div className="price">260 zł</div></li>
  <li><div className="header">Twarz, szyja</div><div className="price">300 zł</div></li>
  <li><div className="header">Twarz, szyja, dekolt</div><div className="price">350 zł</div></li>
  <li><div className="header">Oczy</div><div className="price">350 zł</div></li>
  <li><div className="header">Policzki (blizny potrądzikowe)</div><div className="price">390 zł</div></li>
  <li><div className="header">Brzuch (rozstępy, ujędrnienie skóry)	</div><div className="price">590 zł</div></li>
  <li><div className="otherInfo">Cena za inne części ciała jest ustalana po indywidualnej konsultacji</div></li>
</ul>,
  falaRadiowa: <ul>{/*Fala radiowa - TERMOLIFTING NIEINWAZYJNY*/}
  <li><div className="header">Twarz	</div><div className="price">270 zł</div></li>
  <li><div className="header">Twarz + szyja</div><div className="price">300 zł</div></li>
  <li><div className="header">Twarz + szyja + dekolt</div><div className="price">350 zł</div></li>
  <li><div className="header">Oczy </div><div className="price">220 zł</div></li>
</ul>,
  oczyszczanieWodorowe: <ul>{/*Fala radiowa - Mikroiglowa frakcyjna*/}
  <li><div className="header">Twarz</div><div className="price">500 zł</div></li>
  <li><div className="header">Twarz + szyja</div><div className="price">600 zł</div></li>
  <li><div className="header">Twarz + szyja + dekolt</div><div className="price">750 zł</div></li>
  <li><div className="header">Policzki blizny</div><div className="price">400 zł</div></li>
  <li><div className="header">Oczy</div><div className="price">350 zł</div></li>
  <li><div className="header">Brzuch (rozstępy, ujędrnienie)</div><div className="price">590 zł</div></li>
  <div className="otherInfo">*Cena za inne części ciała jest ustalana po indywidualnej konsultacji</div>
</ul>,
estGen: <ul> {/*EST GEN*/}
  <li><div className="header">Zabieg FOTODYNAMICZNY</div><div className="price">490 zł</div></li>
  <li><div className="header">Zabieg z mikronakłuwaniem</div><div className="price">390 zł</div></li>
  <li><div className="header">Zabieg bankietowy</div><div className="price">450 zł</div></li>
  <li><div className="header">Zabieg kwasowy</div><div className="price">290 zł</div></li>
  <li><div className="header">Zabieg enzymatyczny</div><div className="price">290 zł</div></li>
  <li><div className="header">Zabieg na blizny twarzy</div><div className="price">350 zł</div></li>
  <li><div className="header">Zabieg na rozstępy</div><div className="price">400 zł</div></li>
  
</ul>,
genFactor: <ul>
  <li><div className="header">Kwasy + żel zielony</div><div className="price">300 zł</div></li>
  <li><div className="header">Kwasy + żel czerwony</div><div className="price">350 zł</div></li>
  <li><div className="header">Kwasy z mikronakłuwaniem</div><div className="price">390 zł</div></li>
  <li><div className="header">Retinoid System</div><div className="price">350 zł</div></li>
 
</ul>,
dodatki: <ul>
  <li><div className="header">Światłoterapia LED</div><div className="price">50 zł</div></li>
  <li><div className="header">Oczyszczanie wodorowe</div><div className="price">70 zł</div></li>
  <li><div className="header">Masaż twarzy</div><div className="price">90 zł</div></li>
  <li><div className="header">Mikronakłuwanie</div><div className="price">60 zł</div></li>
  <li><div className="header">Peeling Kawitacyjny</div><div className="price">30 zł</div></li>
</ul>,

kosmetyka_biala: <ul>
<li><div className="header">ILCSI - organiczny zabieg paprykowy</div><div className="price">220 zł</div></li>
<li><div className="header">Skin Simply - zabieg enzymatyczny </div><div className="price">280 zł</div></li>
<li><div className="header">Retix C - retinol z wit. C</div><div className="price">390 zł</div></li>
<li><div className="header">BioRePeel</div><div className="price">290 zł</div></li>
<li><div className="header">PCA Skin - zabieg podstawowy</div><div className="price">300 zł</div></li>
<li><div className="header">PCA Skin - zabieg rozszerzony</div><div className="price">350 zł</div></li>
<li><div className="header">Oczyszczanie Wodorowe basic</div><div className="price">210 zł</div></li>
<li><div className="header">Oczyszczanie Wodorowe silver</div><div className="price">260 zł</div></li>
<li><div className="header">Oczyszczanie Wodorowe gold</div><div className="price">320 zł</div></li>
<li><div className="header">Deep Shoot Anti-Age</div><div className="price">490 zł</div></li>
<li><div className="header">Dermation (40ml)</div><div className="price">350 zł</div></li>
<li><div className="header">Dermation (70ml)</div><div className="price">390 zł</div></li>
<li><div className="header">Masaż KOBIDO</div><div className="price">170 zł</div></li>
<li><div className="header">Masaż KOBIDO z maską</div><div className="price">210 zł</div></li>
<li><div className="header">Rytuał KOBIDO z zabiegiem</div><div className="price">290 zł</div></li>
<li><div className="header">Egzosomy z mikornakłuwaniem</div><div className="price">450 zł</div></li>
<li><div className="header">Karboksyterapia bezigłowa CO2</div><div className="price">270 zł</div></li>
</ul>,
trycho: <ul>
<li><div className="header">Dermation (transdermoporcja)</div><div className="price">300 zł</div></li>
<li><div className="header">Deep Shoot Hair </div><div className="price">490 zł</div></li>
<li><div className="header">Mezoterapia igłowa</div><div className="price">350 zł</div></li>
</ul>,
  depilacjaLaserowa: <ul>
  <ul>EPILACJA LASEROWA
  <li><div className="header">Wąsik</div><div className="price">190 zł</div></li>
  <li><div className="header">Twarz (wąsik, broda, baki)</div><div className="price">250 zł</div></li>
  <li><div className="header">Pachy</div><div className="price">230 zł</div></li>
  <li><div className="header">Przedramiona</div><div className="price">250 zł</div></li>
  <li><div className="header">Ręce całe</div><div className="price">290 zł</div></li>
  <li><div className="header">Plecy </div><div className="price">390 zł</div></li>
  <li><div className="header">Klatka piersiowa </div><div className="price">390 zł</div></li>
  <li><div className="header">Łydki</div><div className="price">290 zł</div></li>
  <li><div className="header">Uda</div><div className="price">290 zł</div></li>
  <li><div className="header">Całe nogi</div><div className="price">550 zł</div></li>
  <li><div className="header">Bikini pachwiny </div><div className="price">250 zł</div></li>
  <li><div className="header">Bikini głębokie</div><div className="price">350 zł</div></li>
  <li><div className="header">Bikini brazylijskie</div><div className="price">390 zł</div></li>
  <div className="otherInfo">Rabat na serię zabiegową jest naliczany podczas konsulacji</div>
  </ul>

  <ul>FOTOODMŁADZANIE
  <li><div className="header">Twarz</div><div className="price">350 zł</div></li>
  <li><div className="header">Twarz + szyja</div><div className="price">390 zł</div></li>
  <li><div className="header">Twarz + szyja + dekolt</div><div className="price">450 zł</div></li>
  </ul>

  <ul>PEELING WĘGLOWY BLACK DOLL
  <li><div className="header">Twarz</div><div className="price">290 zł</div></li>
  <li><div className="header">Twarz + szyja</div><div className="price">330 zł</div></li>
  <li><div className="header">Twarz + szyja + dekolt</div><div className="price">360 zł</div></li>
  <li><div className="header">Plecy</div><div className="price">350 zł</div></li>
  <li><div className="header">Klatka piersiowa</div><div className="price">350 zł</div></li>
  </ul>
</ul>,

stylizacjaRzes: <ul>
<li><div className="header">1:1 założenie </div><div className="price">180 zł</div></li>
<li><div className="header">1:1 uzupełnienie (do 4 tyg.)</div><div className="price">160 zł</div></li>
<li><div className="header">2-3d założenie</div><div className="price">220 zł</div></li>
<li><div className="header">2-3d uzupełnienie (do 4 tyg.)</div><div className="price">170 zł</div></li>
<li><div className="header">4-6d założenie</div><div className="price">250 zł</div></li>
<li><div className="header">4-6d uzupełnienie (do 4 tyg.)</div><div className="price">180 zł</div></li>

  <li>{/*<div className="header">Rzęsy 7-10d założenie</div><div className="price">280 zł</div>*/}</li>
<li>{/*<div className="header">Rzęsy 7-10d uzupełnienie</div><div className="price">od 220 do 260 zł</div>*/}</li>
</ul>,
stylizacjaBrwiIRzes: <ul>
<li><div className="header">Henna brwi/rzęs	</div><div className="price">40 zł</div></li>
<li><div className="header">Henna brwi z regulacją	</div><div className="price">60 zł</div></li>
<li><div className="header">Henna brwi i rzęs z regulacją </div><div className="price">70 zł</div></li>
<li><div className="header">Farbka brwi z regulacją </div><div className="price">75 zł</div></li>
<li><div className="header">Farbka brwi i rzęs z regulacją</div><div className="price">85 zł</div></li>
<li><div className="header">Henna pudrowa brwi z regulacją	</div><div className="price">110 zł</div></li>
<li><div className="header">Henna pudrowa brwi i rzęs z regulacją </div><div className="price">120 zł</div></li>
<li><div className="header">Regulacja brwi</div><div className="price">40 zł</div></li>
<li><div className="header">Depilacja woskiem brwi</div><div className="price">+ 10 zł</div></li>
</ul>,
laminacjaBrwiIRzes: <ul>
<li><div className="header">Laminacja brwi z regulacją </div><div className="price">130 zł</div></li>
<li><div className="header">Laminacja brwi z farbką i regulacją</div><div className="price">150 zł</div></li>
<li><div className="header">Laminacja rzęs</div><div className="price">130 zł</div></li>
<li><div className="header">Laminacja rzęs z farbką</div><div className="price">140 zł</div></li>
<li><div className="header">Pakiet laminacja brwi i rzęs</div><div className="price">250 zł</div></li>
</ul>,
depilacjaWoskiemLycon: <ul>
<li><div className="header">Wąsik</div><div className="price">40 zł</div></li>
<li><div className="header">Twarz (wąsik, broda, policzki)</div><div className="price">55 zł</div></li>
<li><div className="header">Ręce</div><div className="price">70 zł</div></li>
<li><div className="header">Pachy</div><div className="price">60 zł</div></li>
<li><div className="header">Nogi łydki </div><div className="price">70 zł</div></li>
<li><div className="header">Nogi uda</div><div className="price">70 zł</div></li>
<li><div className="header">Nogi całe</div><div className="price">110 zł</div></li>
<li><div className="header">Bikini pachwiny</div><div className="price">70 zł</div></li>
<li><div className="header">Bikini pełne</div><div className="price">170 zł</div></li>
<li><div className="header">Klatka piersiowa</div><div className="price">80 zł</div></li>
<li><div className="header">Plecy</div><div className="price">100 zł</div></li>
</ul>,
zabiegiNaCialo: <ul>
<ul>TERMOGENIQUE

<li><div className="header">Masaż próżniowy - zabieg 30 min</div><div className="price">90 zł</div></li>
<li><div className="header">Masaż próżniowy - zabieg 50 min</div><div className="price">150 zł</div></li>
<li><div className="header">Masaż próżniowy - zabieg 90 min</div><div className="price">180 zł</div></li>
<li><div className="header">Fala Radiowa - zabieg 30 min</div><div className="price">90 zł</div></li>
<li><div className="header">Fala Radiowa - zabieg 50 min	</div><div className="price">150 zł</div></li>
<li><div className="header">Liposukcja kawitacyjna - zabieg 30 min </div><div className="price">90 zł</div></li>
<li><div className="header">Liposukcja kawitacyjna - zabieg 50 min</div><div className="price">150 zł</div></li>

</ul>
<ul>SKIN LIFT 448 kHz
<li><div className="header">Ramiona (pelikany)</div><div className="price">200 zł</div></li>
<li><div className="header">Biust</div><div className="price">200 zł</div></li>
<li><div className="header">Brzuch</div><div className="price">250 zł</div></li>
<li><div className="header">Nogi</div><div className="price">290 zł</div></li>
<li><div className="header">Plecy</div><div className="price">250 zł</div></li>
<li><div className="header">Pachy</div><div className="price">90 zł</div></li>

</ul>
<ul>DERMATION
<li><div className="header">Ramiona (pelikany)</div><div className="price">250 zł</div></li>
<li><div className="header">Brzuch</div><div className="price">300 zł</div></li>
<li><div className="header">Nogi</div><div className="price">350 zł</div></li>
<li><div className="header">Plecy</div><div className="price">300 zł</div></li>
</ul>
</ul>,
zabiegiManualne: <ul>

<li><div className="header">Termogeniczny zabieg modelujący sylwetkę</div><div className="price">250 zł</div></li>
<li><div className="header">Termogeniczny zabieg modelujący sylwetkę pakiet 6 zabiegów</div><div className="price">1350 zł</div></li>
<li><div className="header">Bandaże AROSHA Detoxy</div><div className="price">300 zł</div></li>
<li><div className="header">Bandaże AROSHA No Drain/Lipofit/Fibrocel/Active Up</div><div className="price">280 zł</div></li>
<li><div className="header">Bandaże AROSHA pakiet 5 zabiegów</div><div className="price">1200 zł</div></li>
<li><div className="header">Bandaże AROSHA pakiet 9 zabiegów</div><div className="price">2200 zł</div></li>
<li><div className="header">Masaż bańką chińską 40 min</div><div className="price">110 zł</div></li>
<li><div className="header">Masaż bańką chińską 60 min</div><div className="price">150 zł</div></li>
<li><div className="header">Masaż bańką chińską 90 min</div><div className="price">190 zł</div></li>

</ul>,
  pielegnacjaDloniIStop: <ul>
  <ul>STYLIZACJA PAZNOKCJI
  <li><div className="header">Hybryda - baza</div><div className="price">110 zł</div></li>
  <li><div className="header">Hybryda - kolor</div><div className="price">120 zł</div></li>
  <li><div className="header">Hybryda z nadbudową - baza	</div><div className="price">120 zł</div></li>
  <li><div className="header">Hybryda z nadbudową - kolor	</div><div className="price">130 zł</div></li>
  <li><div className="header">żel na naturalnej płytce</div><div className="price">150 zł</div></li>
  <li><div className="header">żel przedłużenie</div><div className="price">170 zł</div></li>
  <li><div className="header">Manicure klasyczny </div><div className="price">80 zł</div></li>
  <li><div className="header">Manicure klasyczny + kolor</div><div className="price">90 zł</div></li>
  <li><div className="header">Ściągnięcie stylizacji</div><div className="price">65 zł</div></li>
  <li><div className="header">Ściągnięcie stylizacji + manicure</div><div className="price">90 zł</div></li>
  <li><div className="header"><b>Dodatkowo płatne:</b></div><div className="price"></div></li>
  <li><div className="header">Babyboomer</div><div className="price">+10 zł</div></li>
  <li><div className="header">Zdobienia</div><div className="price">+20 zł</div></li>   
  </ul>
  <ul>PEDICURE
  <li><div className="header">Pedicure klasyczny</div><div className="price">150 zł</div></li>  
  <li><div className="header">Pedicure hybrydowy</div><div className="price">170 zł</div></li>  
  <li><div className="header">Pedicure + lakier	</div><div className="price">160 zł</div></li> 
  <li><div className="header">Hybryda w stopach</div><div className="price">110 zł</div></li>
  <li><div className="header">Lakier w stopach</div><div className="price">100 zł</div></li>  
  <li><div className="header">Ściągnięcie hybrydy</div><div className="price">65 zł</div></li> 
  <li><div className="header">Ściągnięcie hybrydy + lakier</div><div className="price"> 85 zł</div></li>     
   
  </ul>  
</ul>
  }
    return(
      <AnimationOnScroll animateIn="animate__backInLeft">
      <div className="offerBackground">
        <div className="offerMenu">
        <div className="offerBlocks">
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('konsultacje')}>Konsultacje</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('stymulatory_tkankowe')}>Stymulatory Tkankowe</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('mezoterapia_iglowa')}>Mezoterapia Igłowa</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('mezoterapia_mikroiglowa')}>Mezoterapia Mikroigłowa</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('mezoterapia_mikroiglowa_RF')}>SKIN LIFT 448 kHz Biostymulacja Skóry</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('falaRadiowa')}>Fala Radiowa – Nieinwazyjny Termolifting</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('oczyszczanieWodorowe')}>Fala Radiowa - Mikroigłowa Frakcyjna</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('estGen')}>EST GEN</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('genFactor')}>GEN FACTOR</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('dodatki')}>Dodatki do zabiegów</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('kosmetyka_biala')}>Kosmetyka Twarzy / Masaże</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('trycho')}>Zabiegi trychologiczne</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('depilacjaLaserowa')}>Zabiegi Laseroterapii Twarz i Ciała Laser 4D Light Medica</div></a>
          {/*<a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('peelingiKwasowe')}>Kwasy, Monokwasy (peelingi chemiczne)</div></a>*/}
          
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('stylizacjaRzes')}>Stylizacja rzęs</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('stylizacjaBrwiIRzes')}>Stylizacji brwi</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('laminacjaBrwiIRzes')}>Laminacja</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('pielegnacjaDloniIStop')}>Pielęgnacja dłoni i stóp</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('zabiegiNaCialo')}>Zabiegi na ciało - aparaturowe</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('zabiegiManualne')}>Zabiegi na ciało - manualne</div></a>
          <a href="#list"><div className="offerBlock" onClick={()=>checkAndSetActiveList('depilacjaWoskiemLycon')}>Depilacja woskiem</div></a>
        </div>
          <div className="list" id="list">
            {activeList.konsultacje && kosmetologia.konsultacje}
            {activeList.mezoterapia_iglowa && kosmetologia.mezoterapia_iglowa}
            {activeList.stymulatory_tkankowe && kosmetologia.stymulatory_tkankowe}
            {activeList.mezoterapia_mikroiglowa && kosmetologia.mezoterapia_mikroiglowa}
            {activeList.mezoterapia_mikroiglowa_RF && kosmetologia.mezoterapia_mikroiglowa_RF}
            {activeList.falaRadiowa && kosmetologia.falaRadiowa}
            {activeList.oczyszczanieWodorowe && kosmetologia.oczyszczanieWodorowe}
            {activeList.estGen && kosmetologia.estGen}
            {activeList.genFactor && kosmetologia.genFactor}
            {activeList.dodatki && kosmetologia.dodatki}
            {activeList.depilacjaLaserowa && kosmetologia.depilacjaLaserowa}
            {activeList.peelingiKwasowe && kosmetologia.peelingiKwasowe}
            {activeList.kosmetyka_biala && kosmetologia.kosmetyka_biala}
            {activeList.trycho && kosmetologia.trycho}
            {activeList.stylizacjaRzes && kosmetologia.stylizacjaRzes}
            {activeList.stylizacjaBrwiIRzes && kosmetologia.stylizacjaBrwiIRzes}
            {activeList.laminacjaBrwiIRzes && kosmetologia.laminacjaBrwiIRzes}
            {activeList.depilacjaWoskiemLycon && kosmetologia.depilacjaWoskiemLycon}
            {activeList.zabiegiNaCialo && kosmetologia.zabiegiNaCialo}
            {activeList.zabiegiManualne && kosmetologia.zabiegiManualne}
            {activeList.pielegnacjaDloniIStop && kosmetologia.pielegnacjaDloniIStop}
            <a className="a_booksyBtn" target="_blank" href="https://booksy.com/pl-pl/103284_salon-kosmetyczny-lilien_salon-kosmetyczny_12733_mazancowice"><div className="booksyBtn">Umów wizytę!</div></a>
          </div>
        </div>
      </div>
      </AnimationOnScroll>
    )
}

export default Offer;
